import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import App from "App";
import CreateRoom from "components/stream/CreateRoom";
import WatchStream from "components/stream/Watch";
import Home from "components/home/Home";
import Chat from "components/chat/Chat";
import Footer from "components/footer/Footer";

const FooterWrapper = (props) => {
	const { children } = props;

	return (
		<div
			style={{
				display: "grid",
				minHeight: "100%",
				gridTemplateRows: "1fr auto",
			}}
		>
			{children}
			<Footer
				style={{
					gridRow: "2 / 3",
				}}
			/>
		</div>
	);
};

const RoutesList = () => {
	return (
		<>
			<Route path="/" component={App} />
			<FooterWrapper>
				<Switch>
					<Route path="/start-stream/:token" component={CreateRoom} />
					<Route path="/chat" component={Chat} />
					<Route path="/:token" component={WatchStream} />
					<Route path="/" component={Home} />
				</Switch>
			</FooterWrapper>
		</>
	);
};

const Root = (props) => (
	<Router {...props}>
		<RoutesList />
	</Router>
);

export default Root;
