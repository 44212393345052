import axios from "axios";

const RequestedDomain = process.env.REACT_APP_API_DOMAIN;

function getAtoken() {
	const defaultUnregisterUserBearer = process.env.REACT_APP_DEFAULT_BEARER;
	// const aToken = `Bearer ${JSON.parse(localStorage.getItem("aToken"))}`;
	const aToken = `Bearer ${defaultUnregisterUserBearer}`;
	return aToken;
}

function storeAToken(response) {
	try {
		localStorage.setItem(
			"aToken",
			JSON.stringify(response.headers.authorization.split(" ")[1])
		);
	} catch (error) {
		console.log(error);
	}
}

const API = {
	post: async (path, payload = {}) => {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
		}).catch((error) => {
			throw error;
		});
		return response;
	},

	get: async (path) => {
		const response = await axios({
			method: "get",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
		}).catch((error) => {
			throw error;
		});
		return response;
	},
};

export { storeAToken, API };
