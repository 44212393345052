import React from "react";
import IframeChat from "./iframe/IframeChat";
import ApiChat from "./jsapi/ApiChat";

export default function Chat(props) {
	const isMobile = (() =>
		/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		))();

	const ChatType = isMobile ? (
		<ApiChat {...props} />
	) : (
		<IframeChat {...props} />
	);

	return props.liveChatID ? ChatType : null;
	// return props.liveChatID ? <ApiChat {...props} /> : null;
}
