import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import "./css/loading.module.css";

function Loading(props) {
	const { fullWidth = false } = props;

	return (
		<div styleName={fullWidth ? "loading full-width" : "loading"}>
			<CircularProgress />
		</div>
	);
}

Loading.defaultProps = {
	fullWidth: false,
};

Loading.propTypes = {
	fullWidth: PropTypes.bool,
};

export default Loading;
