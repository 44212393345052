import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { useTranslation } from "react-i18next";
import { showSnackbar } from "components/snackbar/Snackbar";
import { API } from "functions";
import { useHistory } from "react-router-dom";
import Chat from "components/chat/Chat";
import StreamHeader from "./StreamHeader";
import "./css/watch.module.css";

export default function Watch(props) {
	const watchToken = props?.match?.params?.token;
	const history = useHistory();
	const { t } = useTranslation();
	const [streamInfo, setStreamInfo] = useState({});
	const playerOptions = {
		// height: "460",
		// width: "768",
		playerVars: {
			autoplay: 1,
			origin,
			modestbranding: 1,
			rel: false,
			showinfo: false,
			// color: "white",
			iv_load_policy: 3,
		},
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await API.get(`watch-stream/${watchToken}`);
				setStreamInfo(response.data);
			} catch (error) {
				showSnackbar({ text: t("Error.DeprecatedUrl") });
				setTimeout(() => {
					history.push("/");
				}, 2000);
			}
		};
		fetchData();
	}, [watchToken, history, t]);

	return (
		<div styleName="watch-stream">
			<div styleName="stream-header">
				{streamInfo.userId && <StreamHeader userId={streamInfo.userId} />}
			</div>
			{streamInfo.playerID && (
				<>
					<div styleName="video-player">
						<YouTube
							styleName="iframe-stream"
							videoId={streamInfo.playerID}
							opts={playerOptions}
						/>
					</div>
					<div styleName="chat">
						<Chat
							liveChatID={streamInfo.liveChatID}
							playerId={streamInfo.playerID}
						/>
					</div>
				</>
			)}
			<div styleName="stream-description">
				<div className="title-3 mb_8">{streamInfo.title}</div>
				<div className="text-1 c_base60">{streamInfo.description}</div>
			</div>
		</div>
	);
}
