import { createMuiTheme } from "@material-ui/core/styles";
import colors from "styles/__colors.scss";

export default createMuiTheme({
	palette: {
		primary: {
			main: colors.primary100,
		},
		error: {
			main: colors.additionalError100,
		},
	},
	overrides: {
		MuiFilledInput: {
			root: {
				backgroundColor: colors.base8,
				borderRadius: "4px",
				"&$focused": {
					backgroundColor: colors.primary8,
				},
				"&:hover": {
					backgroundColor: colors.base20,
				},
				"&$error": {
					backgroundColor: colors.additionalError8,
				},
			},
			underline: {
				"&:before": {
					content: "none",
				},
				"&:after": {
					content: "none",
				},
			},
		},
	},
	typography: {
		useNextVariants: true,
	},
});
