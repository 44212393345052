import React from "react";
import "./css/footer.module.css";

// libs
import { useTranslation } from "react-i18next";

export default function HelpLinksSection() {
	const { t, i18n } = useTranslation();
	const lang = i18n.languages[0];

	return (
		<div styleName="section-2">
			<a
				className="text-1 c_base80"
				href={`https://help.payphone.io/faq/${lang}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{t("Dialog.Support.Button.FAQ")}
			</a>
			<a
				className="text-1 c_base80"
				href={`https://docs.payphone.io/${lang}/privacy-policy.html`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{t("Settings.Help.PrivacyPolicy")}
			</a>
			<a
				className="text-1 c_base80"
				href="mailto:support@payphone.io"
				target="_blank"
				rel="noopener noreferrer"
			>
				{t("Settings.Item.Help.Title")}
			</a>
			<a
				className="text-1 c_base80"
				href={`https://docs.payphone.io/${lang}/terms-and-conditions.html`}
				target="_blank"
				rel="noopener noreferrer"
			>
				{t("Settings.Help.Terms")}
			</a>
		</div>
	);
}
