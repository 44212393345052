import React from "react";

// contexts list
import { LightContext, withRoom } from "./contexts/LightContext";

function ContextProvider(props) {
	return (
		<LightContext>
			{props.children}
		</LightContext>
	);
}

export { 
    ContextProvider,
    withRoom 
};