import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationEN from "languages/localization-en.json";
import translationRU from "languages/localization-ru.json";
import translationUK from "languages/localization-uk.json";

const i18nInitOptions = {
	load: "languageOnly",
	whitelist: ["en", "ru", "uk"],
	// we init with resources
	resources: {
		en: {
			translations: translationEN,
		},
		ru: {
			translations: translationRU,
		},
		uk: {
			translations: translationUK,
		},
	},
	// lng: 'en',
	// lng: getFirstBrowserLanguage(),
	fallbackLng: "en",
	debug: false,

	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ",",
	},

	react: {
		wait: true,
	},
};

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	//   .use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init(i18nInitOptions);

export default i18n;
