import React from "react";
import LOGO_IMG from "imgs/logo.svg";
import "./css/header.module.css";
import { Link } from "react-router-dom";

export default function Header() {
	return (
		<header styleName="header">
			<nav styleName="nav">
				<Link to="/">
					<img src={LOGO_IMG} alt="Payphone" />
				</Link>
			</nav>
		</header>
	);
}
