import React, { useEffect, useState } from "react";
import { API } from "functions";
import { useTranslation } from "react-i18next";
import Loading from "components/loading/Loading";
import { showSnackbar } from "components/snackbar/Snackbar";
import WebcamStream from "./WebcamStream";
import EncoderStream from "./EncoderStream";
import CreateRoomSection from "./CreateRoomSection";
import "./css/create-stream.module.css";

export default function CreateRoom(props) {
	const startToken = props?.match?.params?.token;
	const [mode, setMode] = useState("loading");
	const [streamInfo, setStreamInfo] = useState({});
	const { t } = useTranslation;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await API.get(`start-stream/${startToken}`);
				setStreamInfo(response.data);
				setMode("create");
			} catch (error) {
				showSnackbar({ text: t("Error.InvalidStartToken") });
			}
		};
		fetchData();
	}, [startToken, t]);

	return (
		<div styleName="create-room">
			{(() => {
				switch (mode) {
					case "loading":
						return <Loading fullWidth />;
					case "create":
						return (
							<CreateRoomSection setMode={(newMode) => setMode(newMode)} />
						);
					case "webcam":
						return (
							<WebcamStream
								streamInfo={streamInfo}
								back={() => setMode("create")}
							/>
						);
					case "encoder":
						return (
							<EncoderStream
								streamInfo={streamInfo}
								back={() => setMode("create")}
							/>
						);
					default:
						return null;
				}
			})()}
		</div>
	);
}
