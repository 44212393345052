import React from "react";
import PropTypes from "prop-types";
import "./css/blank.module.css";

function Blank(props) {
	const { children, backBtnHandler, backBtn, hidden } = props;

	return (
		<div styleName="blank" hidden={hidden}>
			{(backBtnHandler || backBtn) && (
				<div styleName="header">
					<button
						type="button"
						className="back-btn"
						onClick={backBtnHandler}
						aria-label="Back"
					/>
				</div>
			)}
			{children}
		</div>
	);
}

Blank.defaultProps = {
	backBtnHandler: null,
	backBtn: false,
	hidden: false,
};

Blank.propTypes = {
	children: PropTypes.element.isRequired,
	backBtnHandler: PropTypes.func,
	backBtn: PropTypes.bool,
	hidden: PropTypes.bool,
};

export default Blank;
