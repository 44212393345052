import React from "react";
import Header from "components/header/Header";
import "normalize.css";
import "./styles/styles.css";

function App() {
	return <Header />;
}

export default App;
