import React, { useState } from "react";

const RoomContext = React.createContext();

function LightContext(props) {
	const [isLit, setIsLit] = useState(false);

	const toggleLight = () => {
		setIsLit(!isLit);
	};

	return (
		<RoomContext.Provider
			value={{
				isLit: isLit,
				onToggleLight: () => toggleLight(),
			}}
		>
			{props.children}
		</RoomContext.Provider>
	);
}

function withRoom(Component) {
	return function ConnectedComponent(props) {
		return <RoomContext.Consumer>
            {({ isLit, onToggleLight }) => <Component 
                {...props} 
                isLit={isLit} 
                onToggleLight={onToggleLight} 
            />}
        </RoomContext.Consumer>;
	};
}

export { LightContext, withRoom };
