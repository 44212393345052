import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./css/stream-header.module.css";
import { API } from "functions";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { showSnackbar } from "components/snackbar/Snackbar";

function StreamHeader(props) {
	const { userId } = props;
	const [user, setUser] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await API.get(`get-user-by-id/${userId}`);
				setUser(response.data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [userId]);

	return userId ? (
		<div styleName="stream-header">
			<a
				href={`${process.env.REACT_APP_WEBAPP_DOMAIN}/${userId}`}
				target="_blank"
				rel="noopener noreferrer"
				styleName="user-link"
			>
				<img
					src={user.imageLink}
					className="icon_md__round mr_16"
					alt="error"
				/>
				<div>
					<div className="title-5">{user.name}</div>
					<div className="caption c_base60">
						{t("WatchStream.Header.UserSnippet.ShowInPP")}
					</div>
				</div>
			</a>
			<CopyToClipboard
				text={window.location.href}
				onCopy={() => showSnackbar({ text: t("Notification.Copied") })}
			>
				<div styleName="share-btn" className="icon-btn" />
			</CopyToClipboard>
		</div>
	) : null;
}

StreamHeader.propTypes = {
	userId: PropTypes.string.isRequired,
};

export default StreamHeader;
