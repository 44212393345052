import React from "react";
import "./css/footer.module.css";

// components
import SocialCopySection from "./SocialCopySection";
import HelpLinksSection from "./HelpLinksSection";

export default function Footer() {
	return (
		<div styleName="footer">
			<div styleName="container">
				<HelpLinksSection />
				<SocialCopySection />
			</div>
		</div>
	);
}
