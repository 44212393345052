import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "./css/home.module.css";

import IMG from "imgs/home.svg";

export default function Home() {
	const { t } = useTranslation();

	return (
		<div styleName="home">
			<div styleName="blank">
				<div styleName="content">
					<img src={IMG} alt="no streams" />
					<div className="title-5 mb_16">{t("Home.NoStreams.Title")}</div>
					<div className="text-1 c_base60">
						<Trans i18nKey="Home.NoStreams.Desc">
							But you can get in contact with anyone in
							<a href="https://payphone.io/">Payphone X</a>
						</Trans>
					</div>
				</div>
			</div>
		</div>
	);
}
