import React from "react";
import ReactDOM from "react-dom";
import SnackbarComponent from "./SnackbarComponent";

const modalRoot = document.getElementById("modal-root");

// usage:
// const [show] = Snackbar({ text: "msg" });
// show(true);
export default function Snackbar({ text, autoHideDuration }) {
	const show = (isOpen) => {
		ReactDOM.render(
			isOpen ? (
				<SnackbarComponent
					autoHideDuration={autoHideDuration}
					text={text}
					handleClose={() => show(false)}
				/>
			) : null,
			modalRoot
		);
	};

	return [show];
}

// usage:
// showSnackbar({ text: "msg" });
export function showSnackbar(options) {
	const { text, autoHideDuration } = options;

	ReactDOM.render(
		options ? (
			<SnackbarComponent
				autoHideDuration={autoHideDuration}
				text={text}
				handleClose={() => showSnackbar(false)}
			/>
		) : null,
		modalRoot
	);
}
