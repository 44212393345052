import React from "react";
import MaterialSnackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

export default function SnackbarComponent(props) {
	const { autoHideDuration, text, handleClose } = props;
	const close = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		handleClose();
	};

	return (
		<MaterialSnackbar
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open
			autoHideDuration={autoHideDuration}
			onClose={close}
			message={text}
			action={
				<>
					<IconButton
						size="medium"
						aria-label="close"
						color="inherit"
						onClick={close}
					>
						<CloseIcon fontSize="default" />
					</IconButton>
				</>
			}
		/>
	);
}

SnackbarComponent.defaultProps = {
	autoHideDuration: 2000,
	text: "",
};

SnackbarComponent.propTypes = {
	autoHideDuration: PropTypes.number,
	text: PropTypes.string,
	handleClose: PropTypes.func.isRequired,
};
