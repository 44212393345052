import React from "react";
import PropTypes from "prop-types";
import Blank from "components/blank/Blank";
import "./css/create-stream.module.css";

// libs
import { useTranslation } from "react-i18next";

function EncoderStream(props) {
	const { streamInfo, back } = props;
	const { t } = useTranslation();

	return (
		<Blank backBtnHandler={back}>
			<div styleName="blank-content encoder-content">
				<div className="title-5 mb_16">{t("StartBroadcast.EncoderButton")}</div>
				<div className="text-1 c_base60 mb_24">
					{t("StartBroadcast.Enconder.Description")}
				</div>
				<div styleName="links">
					<div className="text-1">
						<span className="c_base60">{`${t(
							"StartBroadcast.Encoder.PrimServer.Label"
						)}: `}</span>
						{streamInfo.connectURL}
					</div>
					<div className="text-1">
						<span className="c_base60">{`${t(
							"StartBroadcast.Encoder.BackupServer.Label"
						)}: `}</span>
						{streamInfo.backupConnectURL}
					</div>
					<div className="text-1">
						<span className="c_base60">{`${t(
							"StartBroadcast.Encoder.StreamName.Label"
						)}: `}</span>
						{streamInfo.connectName}
					</div>
				</div>
			</div>
		</Blank>
	);
}

EncoderStream.propTypes = {
	streamInfo: PropTypes.objectOf(PropTypes.string).isRequired,
	back: PropTypes.func.isRequired,
};

export default EncoderStream;
