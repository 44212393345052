import React from "react";
import PropTypes from "prop-types";
import Blank from "components/blank/Blank";
import "./css/create-stream.module.css";

// libs
import { useTranslation } from "react-i18next";

// imgs
import WEBCAM_ICON from "imgs/webcam.svg";
import ENCODER_ICON from "imgs/video.svg";

function CreateRoomSection(props) {
	const { setMode } = props;
	const { t } = useTranslation();

	return (
		<Blank>
			<div styleName="blank-content create-room-content">
				<div className="title-5 mb_16">{t("StartBroadcast.Title")}</div>
				<div className="text-1 c_base60">{t("StartBroadcast.Description")}</div>
				<div styleName="start-btns">
					<button
						className="btn_with-icon"
						style={{ backgroundImage: `url(${WEBCAM_ICON})` }}
						type="button"
						onClick={() => setMode("webcam")}
					>
						{t("StartBroadcast.Webcam.Title")}
					</button>
					<div styleName="btns-seperator" className="text-2 c_base60">
						<span />
						{t("Common.Or")}
						<span />
					</div>
					<button
						className="btn_with-icon"
						style={{ backgroundImage: `url(${ENCODER_ICON})` }}
						type="button"
						onClick={() => setMode("encoder")}
					>
						{t("StartBroadcast.EncoderButton")}
					</button>
				</div>
			</div>
		</Blank>
	);
}

CreateRoomSection.propTypes = {
	setMode: PropTypes.func.isRequired,
};

export default CreateRoomSection;
