import React from "react";
import PropTypes from "prop-types";
import "../css/chat.module.css";

function Message(props) {
	const { message } = props;

	return (
		<div styleName="message" className="caption">
			<span styleName="author">{message?.authorDetails?.displayName}</span>
			<span>{message?.snippet?.textMessageDetails?.messageText}</span>
		</div>
	);
}

Message.propTypes = {
	message: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Message;
