import React from 'react';
import './css/footer.module.css';

// libs
import { useTranslation } from 'react-i18next';

// imgs
import INSTA_ICON from "imgs/social/Instagram.svg";
import FB_ICON from "imgs/social/facebook-icon.svg";

export default function SocialCopySection() {
    const { t } = useTranslation();

    return <div styleName="section-3">
        <div styleName="socials">
            <a styleName="link" className="icon_xs" href="https://www.instagram.com/payphone.x/" target="_blank" rel="noopener noreferrer">
                <img src={INSTA_ICON} alt="Instagram"/>
            </a>
            <a styleName="link" className="icon_xs" href="https://www.facebook.com/payphonex.official" target="_blank" rel="noopener noreferrer">
                <img src={FB_ICON} alt="Facebook"/>
            </a>
        </div>
        <div className="text-1 c_base60">
            {t("Footer.Copyright.Label.Web")}
        </div>
    </div>
}